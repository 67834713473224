import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<strong parentName="p">{`Primal Aspects`}</strong>{` are twelve distinct domains that the six `}<a parentName="p" {...{
        "href": "/Primal%20Spirits",
        "title": "Primal Spirits"
      }}>{`Primal Spirits`}</a>{` are tied to, each Primal Spirit sharing two of its Aspects with other Spirits.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "center"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/8f6a22334048fc72f3f3a7cee82c7089/aa440/aspects.png",
            "srcSet": ["/static/8f6a22334048fc72f3f3a7cee82c7089/5ff7e/aspects.png 375w", "/static/8f6a22334048fc72f3f3a7cee82c7089/1d69c/aspects.png 750w", "/static/8f6a22334048fc72f3f3a7cee82c7089/aa440/aspects.png 1500w", "/static/8f6a22334048fc72f3f3a7cee82c7089/e8950/aspects.png 2000w"],
            "width": "700px",
            "className": "center"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`The Primal Aspects`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <h2>{`Energy`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Energy`}</strong>{` gives one the ability to create, shape, and manipulate various forms of energy, and are able to transform or convert it into a number of different manifestations. Some common forms of energy that Acronists use are kinetic, radiant, and thermal. This aspect has ties to the power industry and the military, due to it's applications in combat and energy production. `}<em parentName="p">{`Furybearers`}</em>{` are some of the most sought after Acronists.`}</p>
    <h2>{`Memory`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Memory`}</strong>{` gives one the domain over the `}<em parentName="p">{`concept`}</em>{` of Memory: fabrication, suppression, influence, detection, erasure, and more. Due to the `}<em parentName="p">{`Law of Aversion`}</em>{`, this aspect is tricky to use on other living creatures without consent, but this Aspect is often used to provide memory restoration and preservation. In rare cases of extraordinarily talented Acronists, `}<em parentName="p">{`Memory`}</em>{` is also used to create artificial constructs with sentience.`}</p>
    <h2>{`Material`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Material`}</strong>{` gives one the ability to shape and manipulate `}<em parentName="p">{`matter`}</em>{`, alter it's physical properties or phase state. Most often, this Aspect is uses by Engineers to aid in the construction of infrastructure or manufacturing.`}</p>
    <h2>{`Gravity`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Gravity`}</strong>{` gives one the domain over the fundamental force of Gravity. Primarily, Convictions of this Aspect relate to increasing or decreasing the Gravitational pull of the environment, individual objects, or in rare cases, living creatures. The Aspect of Gravity is used by the Conveyance Authority to operate gravity-powered trains.`}</p>
    <h2>{`Space`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Space`}</strong>{` gives one the ability to manipulate spatial reality, the boundless three-dimensional extent of the Universe in which objects have relative position and direction. Most commonly, this includes increasing and decreasing the `}<em parentName="p">{`amount`}</em>{` of space between points or objects. Most notably, Acronists that use `}<em parentName="p">{`Space`}</em>{` create `}<strong parentName="p">{`Junctions`}</strong>{`, which are links between two points in the world, allowing for instantaneous travel between places. The most notable of these are `}<strong parentName="p">{`Prismagates`}</strong>{`.`}</p>
    <h2>{`Illusion`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Illusion`}</strong>{` gives one the ability to create, shape, and manipulate illusory mirages or simulated reality. These effects are unable to affect reality itself, merely the perceptions of living creatures with Threads.`}</p>
    <h2>{`Force`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Force`}</strong>{` gives one the domain over fundamental forces. Force is a more loosely intepreted Aspect, and it's exact nature is somewehat dependant on the personal beliefs of the Acronist. Most commonly, Force relates to the raw power of Nature, manifesting in the form of wind, storms, and other weather phenomenon.`}</p>
    <h2>{`Life`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Life`}</strong>{` gives one the domain over biological life. Most commonly, this Aspect takes the form of an affinity with plants and animals. Due to the Law of Aversion, this Aspect is typically infeasible to be used for the outright manipulation, creation, or destruction of Life.`}</p>
    <h2>{`Growth`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Growth`}</strong>{` gives one the ability to manipulate the `}<em parentName="p">{`progression`}</em>{` of things. In a sense, Growth is the domain over chronology. Applications of Growth are most often applied to medicine, in order to expedite the healing process.`}</p>
    <h2>{`Shape`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Shape`}</strong>{` gives one the domain over the `}<em parentName="p">{`shapes`}</em>{` of living creatures. Due to the Law of Aversion, applying to this Aspect is nearly impossible, except on the willing, or on oneself.`}</p>
    <h2>{`Will`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Will`}</strong>{` gives one the domain over the `}<em parentName="p">{`volition`}</em>{` of living creatures. This aspect, at a most fundamental level, manipulates the willpower of living creatures, bending them to the Acronist's wishes. In most regions of Naos, this Aspect is highly frowned upon, as it is attached to the Primal Soul.`}</p>
    <h2>{`Emotion`}</h2>
    <p>{`The Aspect of `}<strong parentName="p">{`Emotion`}</strong>{` gives the domain over the `}<em parentName="p">{`emotions`}</em>{` of living creatures, controlling their amplitude and shape.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      